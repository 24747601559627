/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


body {
  background: #eeeeee;
  color: #707070;
}
ion-content {
  --background: #eeeeee;
  --offset-bottom: 77px!important;
  &>.container {
    background: #eeeeee;
    &>ion-list,
    &>ion-grid,
    & > form {
      padding: 0 20px;
      background: transparent;
      gap: 10px;
      display: flex;
      flex-direction: column;
      .flex-column {
        gap: 10px;
        display: flex;
        flex-direction: column;
      }
    }
    & > * {
      &:not(:first-child) {
        margin-top: 15px;
      }
    }
    textarea {
      background: transparent;
      border: 0;
      width: 100%;
    }
  }
  ion-button {
    --border-radius: 20px;
    border-radius: 20px;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 700;
  }
  .ion-no-padding {
    --padding-start: 0 !important;
    --padding-end: 0 !important;
    --padding-top: 0 !important;
    --padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  ion-list {
    &.list-ios,
    &.list-md {
      background: transparent;
    }
    ion-list-header {
      min-height: auto;
      padding: 0;
      ion-label {
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        color: #707070;
      }
    }
    ion-item {
      &.item-interactive {
        --border-width: 0;
        --inner-border-width: 0;
        --show-full-highlight: 0;
        --show-inset-highlight: 0;
      }
      ion-label,
      label {
        font-size: 14px!important;
      }
    }
  }
  h1,
  label {
    color: #707070;
  }
  p {
    margin-top: 0;
    color: #707070;
  }
}

.ion-col {
  &.padding-x {
    padding-left: 20px;
    padding-right: 20px;
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.bg-white {
  background: white;
}
